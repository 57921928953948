import {api, SHARE_LINK_API, DEAL_ROOM_ANALYTICS_API, DEAL_ROOM_API} from '../api';
import {
  IDealRoomBundle,
  IDealRoomBundleUpdateData,
  IDealRoomBundleAddData,
  IDealRoomBundleAddResponse,
  IDealRoomsAnalyticsResponse,
  IDealRoomContentActivityListResponse,
  IDealRoomAnalytics,
  IDealRoomPagination,
} from 'spekit-types';
import {stringifyQs} from '../utils/commonUtils';
import {modify} from '../utils/APIHelpers';

/**
 * Retrieves a specific Bundle by its ID
 * @param bundleId The ID of the Bundle to retrieve
 * @returns Promise with the Bundle data
 */
export const getBundle = (bundleId: string) => {
  return api
    .get<IDealRoomBundle>(`${SHARE_LINK_API}${bundleId}/`)
    .then((res) => res.data);
};

/**
 * Updates a specific Bundle with partial data
 * @param bundleId The ID of the Bundle to update
 * @param data The data to update the Bundle with
 * @returns Promise with the updated Bundle data
 */
export const updateBundle = (bundleId: string, data: IDealRoomBundleUpdateData) => {
  // return api
  //   .patch<IDealRoomBundle>(`${SHARE_LINK_API}${bundleId}/`, data)
  //   .then((res) => res.data);
  return modify<IDealRoomBundleUpdateData, IDealRoomBundle>(
    `${SHARE_LINK_API}${bundleId}/`,
    data
  );
};

/**
 * Adds content items to an existing Deal Room
 * @param data The data to add to the Deal Room
 * @returns Promise with the success and message response data
 */
export const addToDealRoom = (data: IDealRoomBundleAddData) => {
  // ACT-2521: Investigate why this is not working with axios (CORS Error)
  // Undo this and error handling in ShareSettings.tsx once the issue is fixed
  // return api
  //   .patch<IDealRoomBundleAddResponse>(`${DEAL_ROOM_API}`, data)
  //   .then((res) => res.data);
  return modify<IDealRoomBundleAddData, IDealRoomBundleAddResponse>(
    `${DEAL_ROOM_API}`,
    data
  );
};

export const getDealRoomsAnalytics = (pagination: IDealRoomPagination) => {
  const query = stringifyQs(pagination, {
    addQueryPrefix: true,
  });
  return api
    .get<IDealRoomsAnalyticsResponse>(`${DEAL_ROOM_ANALYTICS_API}${query}`)
    .then((res) => res.data);
};

export const getDealRoomAnalytics = (dealRoomId: string) => {
  return api
    .get<IDealRoomAnalytics>(`${DEAL_ROOM_ANALYTICS_API}${dealRoomId}`)
    .then((res) => res.data);
};

export const getContentActivityForDealRoom = (
  dealRoomId: string,
  paginationQuery: IDealRoomPagination
) => {
  const query = stringifyQs(paginationQuery, {
    addQueryPrefix: true,
  });

  return api
    .get<IDealRoomContentActivityListResponse>(
      `${DEAL_ROOM_ANALYTICS_API}${dealRoomId}/content_activity/${query}`
    )
    .then((res) => res.data);
};
